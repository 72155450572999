.footer-contacts {
  display: none;
}

@media (min-width: $ipad-width) {
  .footer-contacts {
    display: flex;
    flex-direction: row;
    width: 500px;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    padding-left: 32px;
    padding-right: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  .footer-contacts__title {
    display: none;
  }

  .footer-contacts__link {
    margin-bottom: 4px;

    &--phone {
      font-size: 24px;
      line-height: 29px;
      color: $text-green;
    }

    &:hover {
      color: $green;
    }
  }
}

@media (min-width: 1150px) {
  .footer-contacts {
    width: 278px;
    padding-left: 10px;
    flex-direction: column;
    align-items: flex-start;
    border-left: 1px solid $green;
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
  }

  .footer-contacts__title {
    display: block;
    font-weight: 500;
    font-size: inherit;
    line-height: inherit;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .footer-contacts__link {
    margin-bottom: 9px;

    &--phone {
      color: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }
}
