.video {
  display: flex;
  flex-direction: column;
  min-width: $iphone-width;
  padding: 0 15px;
  margin-bottom: 40px;
}

.video h1 {
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 20px;
  // padding: 0 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: $green;
}

.video h2 {
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-decoration-line: underline;
}

.video video {
  display: block;
  width: 100%;
  max-width: 690px;
  height: auto;
  margin: 0 auto;
}

.video__youtube-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  // padding-top: 25px;
  height: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 690px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 720px) {
  .video__youtube-wrapper {
    width: 690px;
    padding-bottom: 388px;
  }

  .video h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-decoration: none;
  }
}

@media (min-width: $desktop-width) {
  .video {
    width: $desktop-width;
    margin-left: auto;
    margin-right: auto;
    padding: 0 5px;
  }
}
