.contacts-block {
  margin-top: 15px;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
}

.contacts-block__heading-wrapper {
  // width: 305px;
  margin-right: 15px;
  margin-bottom: 20px;
  min-height: 57px;
  display: flex;
  align-items: center;
  padding: 5px 47px;
  background: $light-bg;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background-image: url("../img/geotag_icon.svg");
  background-repeat: no-repeat;
  background-position: 15px center;

  &--spb {
    padding-right: 5px;
  }
}

.contacts-block__heading {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.contatcts-block__map {
  display: block;
  margin-bottom: 15px;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
  }
}

.contacts-block__content-wrapper {
  padding: 0 15px;
}

.contacts-block__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 9px;
  border-left: 1px solid $green;

  h3 {
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: 500;
  }
}

.contacts-block__text-link {
  display: block;
  margin-bottom: 5px;
  color: inherit;
  text-decoration: none;
}

@media (min-width: $ipad-width) {
  .contacts-block {
    margin-top: 5px;
  }

  .contacts-block__heading-wrapper {
    padding-left: 55px;
  }

  .contacts-block__heading {
    font-size: 26px;
    line-height: 32px;
  }

  .contacts-block__content-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .contatcts-block__map {
    margin-right: 28px;
  }

  .contacts-block__text {
    width: 470px;
    font-size: 24px;
    line-height: 29px;

    h3 {
      font-size: 24px;
      line-height: 29px;
    }
  }

  .contacts-block__text-link {
    margin-bottom: 15px;
  }
}

@media (min-width: $desktop-width) {
  .contacts-block {
    margin-top: 0;
  }

  .contacts-block__heading-wrapper {
    justify-content: flex-end;
    align-items: stretch;
    position: relative;
    left: -50%;
    transform: translateX(192px);
    padding-right: 55px;
    background-image: none;
    padding-top: 10px;
  }

  .contacts-block__heading {
    width: 677px;
    padding-left: 45px;
    background-image: url("../img/geotag_icon.svg");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: contain;
    line-height: 39px;
  }

  .contacts-block__content-wrapper {
    width: $desktop-width;
    margin-left: auto;
    margin-right: auto;
    padding: 0 5px;
  }
}
