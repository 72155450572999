.component {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;

  &--right {
    align-self: flex-end;
  }

  &--left {
    align-self: flex-start;
  }
}

.component__heading {
  margin-left: 15px;
  margin-right: 17px;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.component__text-wrapper {
  max-width: 320px;
}

.component__paragraph {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 0;
  margin-bottom: 8px;

  max-width: 320px;
}

.component__list {
  margin-left: 15px;
  margin-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  list-style-type: "- ";
  border-left: 2px solid $green;
}

.component__image-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 226px;
  min-height: 166px;
  background: $light-gray;

  min-width: 70%;
}

.component--right .component__image-wrapper {
  align-self: flex-end;
  align-items: flex-start;
  border-top-left-radius: 243.5px;
  border-bottom-left-radius: 243.5px;
}

.component--left .component__image-wrapper {
  align-self: flex-start;
  align-items: flex-end;
  border-top-right-radius: 243.5px;
  border-bottom-right-radius: 243.5px;
}

.component__image {
  display: block;

  &--printer {
    transform: translateX(-55px);
  }

  &--engraver {
    transform: translateX(30px);
  }

  &--dryer {
    transform: translateX(-20px);
  }

  &--guidelines {
    transform: translateX(48px);
  }

  &--tool-kit {
    transform: translateX(-24px);
  }
}

// Стили под планшет
@media (min-width: $ipad-width) {
  .component {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    min-height: 321px;
    font-size: 20px;
    line-height: 24px;

    &--left {
      flex-direction: row-reverse;
    }

    &--engraver {
      align-items: flex-start;
    }

    &:first-child {
      align-items: flex-start;
    }
  }

  .component__text-wrapper {
    // width: 385px;

    max-width: 50%;
  }

  .component__heading {
    // margin-top: 12px;
    margin-top: 0;
    margin-left: 17px;
    margin-bottom: 10px;
    font-size: 26px;
    line-height: 32px;

    &--engraver {
      margin-top: 0;
    }
  }

  .component__paragraph {
    margin-bottom: 10px;
    margin-left: 16px;

    max-width: none;
    // margin-right: 18px;
    margin-right: 17px;
  }

  .component__list {
    list-style-position: inside;
    margin-bottom: 0;
    margin-top: 10px;
    margin-left: 16px;
    padding-left: 11px;

    // padding-right: 17px;
    padding-right: 16px;
  }

  .component__image-wrapper {
    align-items: flex-end;
    width: 241px;
    min-height: 321px;

    min-width: 30%;
  }

  .component--right .component__image-wrapper,
  .component--left .component__image-wrapper {
    align-self: center;
    align-items: flex-end;
  }

  .component__image {
    &--printer {
      width: 311px;
      height: 305px;
      transform: translateX(-57px);
    }

    &--engraver {
      width: 239px;
      height: 321px;
      transform: translateX(118px);
    }

    &--dryer {
      width: 216px;
      height: 306px;
      transform: translateX(-153px);
    }

    &--guidelines {
      width: 326px;
      height: 262px;
      transform: translateX(130px) translateY(5px);
    }

    &--tool-kit {
      width: 250px;
      height: 279px;
      transform: translateX(-105px);
    }
  }
}

// Стили под десктоп
@media (min-width: 1150px) {
  .component {
    justify-content: flex-end;
    align-items: flex-start;

    max-width: none;
  }

  .component__text-wrapper {
    display: flex;
    flex-direction: column;
    width: 680px;
    flex-grow: 1;

    max-width: none;

    &--left {
      // margin-left: 126px;
      align-items: flex-start;
      margin-left: 0;
      padding-left: 113px;
      width: 687px;
    }

    &--right {
      // margin-right: 126px;
      align-items: flex-end;
      margin-right: 0;
      padding-right: 132px;
    }
  }

  .component__heading {
    width: 530px;
    margin-top: 20px;
    margin-right: 0;
    margin-left: 14px;
  }

  .component__paragraph {
    width: 530px;
    margin-right: 0;
    margin-left: 14px;
    padding-right: 100px;

    max-width: none;
  }

  .component__list {
    width: 530px;
    margin-top: 0;
    margin-left: 14px;
    padding-right: 80px;
    padding-left: 24px;
    list-style-position: outside;
  }

  .component__image-wrapper {
    width: 470px;
    flex-grow: 1;

    &--left {
      width: 463px;
    }
  }

  .component--right .component__image-wrapper {
    align-items: flex-start;
  }

  .component__image {
    &--printer {
      width: 311px;
      height: 305px;
      transform: translateX(-40px);
    }

    &--engraver {
      width: 237px;
      height: 321px;
      transform: translateX(-2px);
    }

    &--dryer {
      width: 216px;
      height: 306px;
      transform: translateX(-30px);
    }

    &--guidelines {
      width: 362px;
      height: 290px;
      transform: translateX(70px);
    }

    &--tool-kit {
      width: 259px;
      height: 290px;
      transform: translateX(-35px);
    }
  }
}
