.item-card {
  min-width: $iphone-width;
  font-size: 12px;
  line-height: 15px;

  > section:last-child {
    margin-bottom: 20px;
  }
}

.item-card__youtube-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
  max-width: 690px;
  margin: 0 auto;
  margin-top: 10px;

  &--engraving {
    max-width: 632px;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.item-card__text-block {
  width: 100%;
  padding: 0 15px;

  h1,
  h2 {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: $green;
  }

  p {
    margin: 0;
    text-indent: 15px;
    text-align: justify;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  h3 {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: $green;
  }

  strong {
    text-decoration: underline;
  }

  &--article {
    margin-bottom: 40px;
    /* display: flex;
    flex-direction: column;
    align-items: center; */

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      margin-top: 20px;
      width: auto;
      height: auto;
      max-width: 100%;
    }

    h1,
    h2 {
      font-weight: 500;
    }

    ul {
      list-style-type: "- ";
      list-style-position: inside;
      text-align: justify;
      margin-top: 12px;
      // margin-bottom: 20px;
      text-indent: 15px;
    }

    ol {
      padding-left: 0;
      text-align: justify;
      list-style-position: inside;
      text-indent: 14px;

      ol {
        margin-top: 10px;
        margin-bottom: 10px;
        list-style-type: lower-latin;
        padding-left: 25px;
        text-indent: 0;
      }
    }
  }

  &--milling {
    h1,
    h2 {
      font-size: 17px;
      line-height: 21px;
    }
  }
}

.item-card__interactive-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
  padding-top: 20px;
  margin-bottom: 16px;

  h2 {
    width: 100%;
    // margin-top: 20px;
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: $green;
  }
}

.item-card__documentation-block {
  padding: 0 15px;
  margin-bottom: 20px;
}

.item-card__video-block {
  margin: 0 15px;
  // width: 100%;

  video {
    margin-top: 0;
  }
}

.item-card__content-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 672px) {
  .item-card__youtube-wrapper {
    &--engraving {
      width: 632px;
      padding-bottom: 356px;
    }
    // width: 690px;
    // padding-bottom: 388px;
  }
}

@media (min-width: $ipad-width) {
  .item-card {
    margin-top: 40px;
    font-size: 17px;
    line-height: 21px;

    section:last-child {
      margin-bottom: 40px;
    }

    h2 {
      font-size: 26px;
      line-height: 32px;
    }

    h3 {
      font-size: 17px;
      line-height: 21px;
    }
  }

  .item-card__video {
    width: 690px;
    height: 383px;
  }

  .item-card__interactive-block {
    margin-bottom: 33px;
  }

  .item-card__text-block {
    h1 {
      font-size: 26px;
      line-height: 32px;
    }

    &--scara {
      margin-top: 20px;

      img {
        margin-bottom: 15px;
      }
    }

    &--article {
      ol {
        margin-top: 0;
        margin-bottom: 20px;

        ol {
          padding-left: 128px;
        }
      }
    }
  }

  .item-card__video-block {
    video {
      // width: 632px;
      width: auto;
      height: 351px;
    }
  }

  .item-card__documentation-block {
    margin-bottom: 40px;
  }
}

@media (min-width: $desktop-width) {
  .item-card {
    margin-top: 30px;
  }

  .item-card__text-block,
  .item-card__interactive-block {
    width: $desktop-width;
    padding: 0 27px;
    margin-left: auto;
    margin-right: auto;
  }

  .item-card__text-block {
    display: block;

    &--scara {
      margin-top: 40px;
      padding: 0;

      img {
        float: left;
        margin-right: 30px;
      }

      p {
        overflow: unset;
        padding: 0 30px;

        &:first-of-type {
          margin-top: 40px;
        }
      }
    }

    &--milling {
      padding: 0 7px;

      p {
        padding: 0 25px;
      }

      ul {
        padding-left: 10px;
      }

      ol {
        padding-left: 25px;
        padding-right: 25px;

        ol {
          padding-left: 70px;
          padding-right: 0;
        }
      }
    }
  }

  .item-card__interactive-block {
    display: flex;
    justify-content: center;
  }

  .item-card__text-block ul {
    padding-left: 15px;
  }

  .item-card__content-wrapper {
    flex-direction: row;
    // flex-wrap: wrap;
  }

  .item-card__section-wrapper {
    width: $desktop-width;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .item-card__video-block {
    margin: 0;

    video {
      width: auto;
    }
  }

  .item-card__documentation-block {
    width: 1150px;
    padding: 0 5px;
    margin-left: auto;
    margin-right: auto;
  }
}
