.video-block {
  display: block;
  margin: 0 auto;

  &--price {
    padding: 0 15px;
  }
}

.video-block__video {
  width: 290px;
  height: 161px;

  display: block;
  margin: 0 auto;
}

.video-block video {
  display: block;
  width: 100%;
  max-width: 461px;
  margin-top: 15px;
  // margin: 0 15px;
}

.video-block__title {
  font-size: 12px;
  line-height: 15px;
  text-indent: 15px;

  display: block;
  // width: 380px;
  margin: 0 auto;
  margin-top: 7px;
  text-align: center;
}

@media (min-width: $ipad-width) {
  .video-block__video {
    width: 672px;
    height: 373px;

    // margin-left: 33px;
    // margin-right: 33px;
  }

  .video-block video {
    width: 461px;
    height: 261px;
    max-width: 100%;
  }

  .video-block__title {
    font-size: 17px;
    line-height: 21px;

    width: 672px;
    margin-top: 16px;
  }
}

@media (min-width: $desktop-width) {
  .video-block__video {
    width: 1027px;
    height: 570px;
  }

  .video-block__title {
    margin-top: 6px;
  }
}
