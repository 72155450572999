// Стили общие + стили под мобильный
.training-welcome {
  background: $dark-bg;
  color: $text-white;
}

.training-welcome__wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 14px 0 20px 0;

  // width: $iphone-width;
  min-width: 320px;
  // padding-right: 30px;
}

.training-welcome__text-wrapper {
  padding-right: 30px;
}

.training-welcome__heading {
  margin-top: 0;
  margin-bottom: 11px;
  padding-left: 28px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  // max-width: 230px;
  padding-right: 40px;
}

.training-welcome__subtitle,
.training-welcome__description {
  display: none;
}

.training-welcome__photo-wrapper {
  width: 246px;
  height: 182px;
  background: $light-gray;
  border-top-right-radius: 243.5px;
  border-bottom-right-radius: 243.5px;

  min-width: 50%;
  display: flex;
  justify-content: flex-end;
}

.training-welcome__photo {
  position: relative;
  left: 44px;
}

.training-welcome__button-wrapper {
  width: 305px;
  padding-left: 9px;
  padding-top: 10px;
  padding-bottom: 10px;
  align-self: flex-end;
  margin-top: 20px;
  background: $light-bg;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;

  min-width: 90%;
}

.training-welcome__button {
  // margin-top: 10px;
  // margin-bottom: 10px;
  margin-right: 15px;

  // max-width: 280px;
  // min-height: 42px;
  width: 95%;
  padding-top: 8px;


  /* &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 305px;
    height: 63px;
    background: $light-bg;
    z-index: -1;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  } */
}

// Стили под планшет
@media (min-width: $ipad-width) {
  .training-welcome__wrapper {
    position: relative;
    padding-left: 350px;
    padding-top: 20px;

    // width: $ipad-width;
    min-width: $ipad-width;
    min-height: 344px;
  }

  .training-welcome__text-wrapper {
    padding-right: 20px;
  }

  .training-welcome__heading {
    font-size: 26px;
    line-height: 32px;
    padding-left: 0;
    max-width: 320px;
  }

  .training-welcome__subtitle,
  .training-welcome__description {
    display: block;
    margin-top: 0;
    margin-bottom: 20px;
    padding-left: 5px;
    font-size: 12px;
    line-height: 15px;
    border-left: 1px solid $green;
  }

  .training-welcome__subtitle {
    font-weight: 500;
    max-width: 200px;
  }

  .training-welcome__photo-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 30px;
    left: 0;
    width: 288px;
    height: 284px;

    min-width: auto;
  }

  .training-welcome__photo {
    width: 300px;
    height: 239px;
    top: 12px;
    left: 40px;
  }

  .training-welcome__button-wrapper {
    margin-top: 22px;
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 15px;

    // width: 418px;
    width: 100%;
  }

  .training-welcome__button {
    max-width: 370px;
    min-height: auto;
    padding: 8px 30px;
  }
}

// Стили под десктоп
@media (min-width: 1150px) {
  .training-welcome__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    flex-wrap: wrap;
    // max-width: $desktop-width;
    min-height: 545px;
    padding-left: 0;
    padding-top: 25px;
  }

  .training-welcome__text-wrapper {
    width: 50%;
    flex-shrink: 0;
    justify-content: flex-start;
    // margin-left: 36px;
    padding-left: 16px;
  }

  .training-welcome__heading {
    max-width: 450px;
    margin-top: 40px;
    margin-bottom: 21px;
    font-size: 36px;
    line-height: 44px;
  }

  .training-welcome__subtitle,
  .training-welcome__description {
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 22px;
  }

  .training-welcome__subtitle {
    max-width: 285px;
  }

  .training-welcome__description {
    max-width: 560px;
  }

  .training-welcome__button-wrapper {
    margin-top: 15px;
    width: 49%;
    min-width: auto;

    padding-top: 15px;
    padding-bottom: 17px;
  }

  .training-welcome__button {
    max-width: 372px;
  }

  .training-welcome__photo-wrapper {
    position: absolute;
    top: 24px;
    width: 47.5%;
    height: 487px;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .training-welcome__photo {
    width: 592px;
    height: 471px;
    top: 0;
  }
}
