.main-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 40px;
  font-size: 12px;
  line-height: 15px;
  background: $main-bg;
  color: $main-color;
  font-family: $font;

  padding-left: 15px;
  padding-right: 15px;

  // width: $iphone-width;
  min-width: $iphone-width;
}

.main-block > section {
  display: flex;
  // max-width: 100%;
  width: 100%;
  // width: auto;
  flex-direction: column;
  align-items: stretch;
}

.main-block h1,
.main-block h2 {
  margin-top: 20px;
  margin-bottom: 18px;
  color: $text-green;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.main-block p,
.main-block ul {
  font-size: 12px;
  line-height: 15px;
  text-align: justify;

  &.no-break {
    margin-top: 0;
  }
}

.main-block p {
  text-indent: 15px;
}

.main-block img {
  display: block;
}

/* .main-block__wrapper {
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;

  // width: $iphone-width;
  min-width: $iphone-width;
} */

@media (min-width: $ipad-width) {
  .main-block {
    // width: $ipad-width;
    min-width: $ipad-width;
  }

  .main-block h1,
  .main-block h2 {
    font-size: 26px;
    line-height: 32px;
    margin-left: 12px;
    margin-bottom: 10px;
  }

  .main-block p,
  .main-block ul {
    font-size: 17px;
    line-height: 21px;
    margin: 0;
  }
}

@media (min-width: $desktop-width) {
  .main-block {
    margin-bottom: 40px;
    width: $desktop-width;
    padding-left: 26px;
    padding-right: 37px;
  }

  .main-block__sections-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    section {
      width: 49%;
    }
  }

  /* .main-block__wrapper {
    width: $desktop-width;
    padding-left: 26px;
    padding-right: 37px;
  } */
}
