// Стили общие + стили под мобильный
.training-kit__wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;


  // width: $iphone-width;
  min-width: 320px;
}

.training-kit__heading-wrapper {
  align-self: flex-start;
}

.training-kit__heading {
  margin: 20px 0;
  padding: 6px 20px;
  max-width: 250px;
  background: $green-bg;
  color: $text-white;
  border-radius: 0 30px 30px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.training-kit__list {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  list-style: none;
  padding-left: 0;
}

// Стили под планшет
@media (min-width: $ipad-width) {
  .training-kit__wrapper {
    position: relative;

    // width: $ipad-width;
    min-width: $ipad-width;
  }

  .training-kit__heading-wrapper {
    width: 370px;
  }

  .training-kit__heading {
    max-width: 100%;
    min-width: 370px;
    margin-top: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 26px;
    line-height: 32px;
  }

  .training-kit__item {
    margin-bottom: 20px;
  }

  .training-kit__item:first-child {
    margin-top: -70px;

    .training-kit__item-title {
      margin-top: 75px;
    }
  }
}

// Стили под десктоп
@media (min-width: 1150px) {
  .training-kit__wrapper {
    width: 100%;
  }

  .training-kit__heading-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 42%;
    margin-top: 18px;
    background: $green-bg;
    border-radius: 0 30px 30px 0;
  }

  .training-kit__heading {
    width: 470px;
    margin: 0;
    padding-left: 30px;
    padding-right: 30px;
    background: none;
    text-align: left;
  }

  .training-kit__item:first-child {
    margin-top: -52px;

    .training-kit__item-title {
      margin-top: 92px;
    }
  }
}
