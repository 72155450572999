.page-header {
  width: 100%;
  background: $header-bg;
  color: $header-color;
  font-family: $font;
}

.page-header__wrapper {
  border-bottom: 1px solid $gray-border;
  padding: 12px 15px 8px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  // width: $iphone-width;
  min-width: 320px;
}

.page-header__logo {
  padding-right: 12px;
}

.page-header__logo-img {
  display: block;
}

.page-header__contacts {
  flex-grow: 1;
}

.page-header__menu {
  align-self: center;
}

.page-header__menu-button {
  position: relative;
  display: block;
  width: 36px;
  height: 28px;
  align-self: center;

  background: none;
  border: none;
  box-shadow: none;

  &::after {
    position: absolute;
    content: "";
    width: 36px;
    height: 4px;
    left: 0;
    background: $menu-btn-color;
    border-radius: 3px;
    box-shadow: $menu-btn-color 0 10px,
      $menu-btn-color 0 -10px;
  }

  &:focus {
    outline: none;
    opacity: 0.5;
  }

  &--close {
    &:focus {
      opacity: 1;
    }

    &::after {
      box-shadow: none;
      transform: rotate(45deg);
    }

    &::before {
      position: absolute;
      content: "";
      width: 36px;
      height: 4px;
      left: 0;
      background: $menu-btn-color;
      border-radius: 3px;
      transform: rotate(-45deg);
    }
  }
}

// Стили под планшет
@media (min-width: $ipad-width) {
  .page-header__wrapper {
    padding-top: 8px;
    padding-bottom: 12px;

    // width: $ipad-width;
    min-width: $ipad-width;

  }

  .page-header__logo-img {
    width: 167px;
  }

  .page-header__contacts {
    margin-right: auto;
    padding-top: 8px;
  }

  .page-header__menu-button {
    cursor: pointer;
  }
}

// Стили под десктоп
@media (min-width: 1150px) {
  .page-header {
    font-size: 13px;
    line-height: 16px;
  }

  .page-header__wrapper {
    width: 1150px;
    border: none;
    padding-left: 5px;
    padding-right: 5px;
  }

  .page-header__contacts {
    order: 1;
    margin-right: 0;
    width: 167px;
    flex-grow: 0;
  }

  .page-header__menu-button {
    display: none;
  }

  .page-header__menu {
    width: 770px;
    flex-grow: 1;
    align-self: stretch;
    // width: 795px;
  }

  .page-header__logo {
    padding-left: 2px;
    padding-right: 8px;
  }
}
