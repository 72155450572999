.not-found {
  min-width: $iphone-width;
  position: relative;
  background: $dark-bg;
  color: #f4f4f4;
  width: 100%;
  height: 100vh;
}

.not-found__message-block {
  width: 100%;
  min-width: $iphone-width;
  padding: 0 10px;
  position: fixed;
  top: 31vh;
  // margin-top: -70px;
  // bottom: 50%;
  left: 0;
  // right: 50%;
}

.not-found__code {
  margin: 0;
  color: $green;
  font-size: 70px;
  line-height: 85px;
  text-align: center;
}

.not-found__text {
  width: 300px;
  margin: 0 auto;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.not-found__link {
  min-width: $iphone-width;
  position: fixed;
  display: block;
  width: 100%;
  text-align: center;
  color: inherit;
  bottom: 40px;
  font-size: 20px;
  line-height: 24px;
}

@media (min-width: $ipad-width) {
  .not-found__code {
    font-size: 144px;
    line-height: 176px;
  }

  .not-found__text {
    width: 320px;
    font-size: 36px;
    line-height: 44px;
  }
}
