.simple-button {
  display: block;
  padding: 5px 40px;
  max-width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 30px;

  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  color: $text-white;
  text-decoration: none;
  background: $green-bg;

  &:hover,
  &:focus {
    border: none;
    outline: none;
    background: $dark-bg;
    color: $text-green;
  }
}
