.example {
  display: flex;
  flex-direction: column;
  min-width: $iphone-width;
  padding: 0 15px;
  margin-bottom: 25px;
}

.example h1 {
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 20px;
  // padding: 0 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: $green;
}

.example h2 {
  width: 100%;
  color: $green;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.example__link {
  color: $green;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.example__text-wrapper {
  font-size: 12px;
  line-height: 15px;

  > p {
    text-align: justify;
    text-indent: 15px;
    margin-top: 0;
    overflow: unset;
    min-width: 290px;
    margin-bottom: 0;
  }

  > a {
    color: inherit;
  }

  .example__text--right {
    text-align: right;
  }

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    display: block;
    margin: 20px auto;
  }

  ul {
    border-left: 1px solid $green;
    padding-left: 10px;
    list-style: none;
  }

  .example__list-heading {
    font-size: 15px;
    line-height: 18px;
    text-align: left;
    text-decoration: underline;
    text-indent: 0;
  }
}

.example__content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  &--skyone {
    margin-bottom: 20px;

    ul {
      align-self: flex-start;
      margin: 0;
    }

    img {
      margin: 0;
    }
  }
}

.example__picture-wrapper {
  margin-bottom: 20px;
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  &--order-last {
    order: 1;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  &--education {
    > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &--clamp,
  &--goffer,
  &--bracket,
  &--rea {
    img {
      margin-bottom: 0;
    }
  }
}

.example__external-link {
  display: block;
  text-indent: 15px;
  text-align: justify;
  margin-top: 20px;
  margin-bottom: 15px;
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.example__video {
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto;
  margin-top: 20px;
}

@media (min-width: 390px) {
  .example__content-wrapper {
    &--skyone {
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;

      ul {
        align-self: center;
      }
    }
  }
}


@media (min-width: 570px) {
  .example {
    img {
      width: auto;
      height: auto;
      max-width: 100%;
    }
  }

  .example__content-wrapper {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: center;

    &--float-box {
      display: block;

      p,
      h2 {
        overflow: unset;
      }
    }

    &--thruster {
      justify-content: space-between;

      > * {
        max-width: 49%;
      }
    }

    &--skyone {
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }

  .example__picture-wrapper {
    flex-shrink: 0;
    flex-direction: row;
    // align-items: flex-start;
    min-width: 247px;

    &--turn-signal {
      flex-direction: row;
    }

    &--clamp,
    &--goffer,
    &--bracket,
    &--rea {
      flex-direction: row;
      flex-wrap: nowrap;
      min-height: 0;
      // margin-bottom: 0;

      img {
        padding: 0 5px;
        // margin: 0;
      }
    }
  }
}

@media (min-width: $ipad-width) {
  .example {
    margin-bottom: 40px;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
    }
  }

  .example h1 {
    font-size: 26px;
    line-height: 32px;
    margin-top: 23px;
  }

  .example h2 {
    font-size: 26px;
    line-height: 32px;
    margin: 20px 0;
  }

  .example__content-wrapper {
    justify-content: center;

    &--float-box {
      display: block;
      margin-top: 3px;
      // margin-bottom: 15px;

      p,
      h2 {
        overflow: unset;
      }
    }

    &--education {
      flex-direction: column;
    }

    &--thruster {
      margin-top: 4px;
      justify-content: space-between;

      > * {
        max-width: 48.5%;
      }
    }

    &--skyone {
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;

      > * {
        width: 50%;
      }

      img {
        margin-left: 0;
        margin-top: 0;
      }
    }
  }

  .example__text-wrapper {
    font-size: 17px;
    line-height: 21px;

    > p {
      margin-bottom: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      border-width: 2px;
    }

    .example__list-heading {
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 17px;
      line-height: 21px;
      text-align: justify;
      text-decoration: none;
    }
  }

  .example__picture-wrapper {
    min-width: 256px;
    min-height: 490px;
    align-items: flex-start;
    justify-content: center;

    &--clamp,
    &--goffer,
    &--rea,
    &--bracket {
      flex-direction: row;
      flex-wrap: nowrap;
      min-height: 0;
      margin-right: 0;

      > * {
        flex-shrink: 1;

        &:not(:last-child) {
          margin-bottom: 0;
          margin-right: 30px;
        }
      }
    }

    &--turn-signal {
      justify-content: center;
      min-height: 0;
    }

    img {
      width: auto;
      height: auto;
    }
  }

  .example__documentation {
    display: flex;
  }

  .example__external-link {
    margin-bottom: 0;
  }

  .example__video {
    max-width: 690px;
  }
}

@media (min-width: $desktop-width) {
  .example {
    width: $desktop-width;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 19px;
    padding-left: 19px;
    padding-right: 19px;

    h1 {
      margin-top: 30px;
    }
  }

  .example__picture-wrapper {
    min-width: 293px;
    // min-height: 557px;

    &--clamp,
    &--goffer,
    &--bracket,
    &--rea,
    &--turn-signal {
      min-height: 0;
    }

    &--turn-signal {
      justify-content: space-between;
    }
  }

  /* .example__content-wrapper {
    &--thruster {
      > * {
        max-width: 555px;
      }
    }
  } */

  .example__content-wrapper > *:last-child {
    margin-left: auto;
  }
}
