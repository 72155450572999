.printer-welcome {
  color: $text-black;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
}

.printer-welcome__wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 21px;
  margin: 0 auto;

  justify-content: flex-start;
  align-items: center;
  min-width: $iphone-width;
}

.printer-welcome__title {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 14px;
  padding: 0 28px;

  font-size: 20px;
  line-height: 24px;
  text-align: left;
  font-weight: 500;
}

.printer-welcome__text-wrapper {
  padding-right: 14px;

  width: 48%;
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 20px;
  padding-left: 10px;
  border-left: 1px solid $green;
}

.printer-welcome__text {
  &--additional {
    display: none;
  }

  b {
    font-weight: 700;
  }
}

.printer-welcome__photo-wrapper {
  background: $light-gray;
  border-radius: 0 243.5px 243.5px 0;
  width: 155px;
  height: 182px;
  min-width: 30%;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
}

.printer-welcome__photo {
  position: relative;
  top: -55px;
  // left: 38px;
  // width: 224px;
  height: auto;

  right: -70px;
}

.printer-welcome__button-box {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 305px;
  margin-top: 20px;
  margin-bottom: 0;
  margin-left: auto;
  background: #f2f2f2;
  padding-left: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 30px 0 0 30px;

  min-width: 95%;
}

.printer-welcome__button {
  min-width: 63%;
  padding-top: 9px;
  padding-bottom: 9px;
}

@media (min-width: $ipad-width) {
  .printer-welcome {
    font-size: 12px;
    line-height: 15px;
  }

  .printer-welcome__wrapper {
    flex-direction: column;
    align-items: flex-end;
    min-height: 314px;
    padding-left: 350px;
    padding-top: 30px;

    // width: $ipad-width;
    min-width: $ipad-width;
  }

  .printer-welcome__title {
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 0;
    font-size: 26px;
    line-height: 32px;
    font-weight: 500;
  }

  .printer-welcome__text-wrapper {
    width: auto;
    margin-left: 0;
    margin-bottom: 5px;
    padding-left: 5px;
  }

  .printer-welcome__text {
    b {
      font-weight: 300;
    }

    &--additional {
      display: inline;
    }
  }

  .printer-welcome__photo-wrapper {
    // width: 288px;
    // height: 284px;
    position: absolute;
    top: 14px;
    left: 0;
    width: 288px;
    height: 284px;
    border-top-right-radius: 242px;
    border-bottom-right-radius: 242px;

    min-width: auto;
    display: block;
  }

  .printer-welcome__photo {
    width: 375px;
    height: 412px;
    top: -60px;
    right: -27px;
  }

  .printer-welcome__button-box {
    width: 100%;
    margin-top: auto;
  }

  .printer-welcome__button {
    min-width: 45%;
  }
}

@media (min-width: $desktop-width) {
  .printer-welcome {
    position: relative;
  }

  .printer-welcome__wrapper {
    position: static;
    width: 62.5%;
    min-width: auto;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 55px;
    padding-left: 0;
    padding-right: 0;
    min-height: 536px;
    margin-left: auto;
    margin-right: 0;
  }

  .printer-welcome__title {
    max-width: 700px;
    margin-top: 50px;
    margin-bottom: 40px;
    font-size: 36px;
    line-height: 44px;
  }

  .printer-welcome__text-wrapper {
    max-width: 80%;
    padding-left: 12px;
  }

  .printer-welcome__text {
    font-size: 17px;
    line-height: 21px;
    text-indent: 15px;
    text-align: justify;
    max-width: 720px;
  }

  .printer-welcome__photo-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 35.5%;
    height: 487px;
    top: 25px;
  }

  .printer-welcome__photo {
    width: 489px;
    height: 542px;
    top: -16px;
    right: -85px;
  }

  .printer-welcome__button-box {
    margin-top: 45px;
    padding-left: 17px;
  }

  .printer-welcome__button {
    min-width: 20.5%;
  }
}
