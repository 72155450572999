.price-welcome {
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.price-welcome p {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: $ipad-width) {
  .price-welcome {
    padding-top: 3px;
  }
}

@media (min-width: $desktop-width) {
  .price-welcome {
    padding: 0;
  }

  .price-welcome h1 {
    margin-top: 30px;
  }

  .price-welcome__wrapper {
    width: $desktop-width;
    margin: 0 auto;
    padding: 0 23px;
  }
}
