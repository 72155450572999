.advantages__text {
  text-indent: 15px;
  margin: 0;
  word-spacing: 1px;
}

.advantages__list {
  margin-top: 15px;
  list-style: none;
  padding-left: 0;
}

@media (min-width: $ipad-width) {
  .advantages__text {
    word-spacing: 0;
  }

  .advantages__list {
    margin-top: 21px;
  }
}

@media (min-width: $desktop-width) {
  .advantages__title {
    margin-bottom: 17px;
  }
}
