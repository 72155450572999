.page-footer {
  background: $footer-bg;
  color: $footer-color;
  font-family: $font;
  width: 100%;
}

.page-footer__wrapper {
  min-width: 320px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.page-footer__section1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: $light-gray;
  color: $main-color;
}

.page-footer__section2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
}

.page-footer__link {
  display: block;
  text-decoration: none;
  color: inherit;
}

.page-footer__legal-info {
  width: 227px;
  padding-right: 10px;
  border-right: 1px solid $green;
}

.page-footer__logo {
  display: block;
  width: 73px;
  flex-shrink: 0;
  height: 60px;
  padding-left: 7px;
  align-self: center;
}

.page-footer__logo-img {
  display: block;
}

@media (min-width: $ipad-width) {
  .page-footer__wrapper {
    display: flex;
    justify-content: space-between;
  }

  .page-footer__section1 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 7px;
  }

  .page-footer__section2 {
    padding-top: 10px;
    padding-bottom: 12px;
  }

  .page-footer__logo {
    width: 187px;
  }

  .page-footer__logo-img {
    width: 170px;
  }
}

@media (min-width: 1150px) {
  .page-footer__wrapper {
    flex-direction: row;
    justify-content: space-between;
    width: 1150px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .page-footer__section1 {
    width: 550px;
    padding-left: 6px;
    align-items: flex-start;
    background: none;
    color: $text-white;
  }

  .page-footer__section2 {
    width: 600px;
    padding-right: 0;
    justify-content: flex-end;
    padding-top: 0;
  }

  .page-footer__contacts {
    margin-right: auto;
    align-self: flex-start;
  }

  .page-footer__legal-info {
    padding-right: 15px;
    padding-top: 2px;
  }

  .page-footer__logo {
    padding-left: 9px;
    padding-top: 1px;
  }
}
