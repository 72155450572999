.main-navigation {
  position: relative;

  &--closed {
    display: none;
  }
}

.main-navigation__list {
  position: absolute;
  right: 0;
  padding: 8px 0;

  background: $header-bg;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  list-style: none;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;

  &--level1 {
    z-index: 1;
    text-transform: uppercase;
    min-width: 163px;
    padding-left: 23px;
    padding-right: 23px;
  }

  &--level2 {
    z-index: 2;
    top: 100%;
    text-transform: none;
    visibility: hidden;
    min-width: 163px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #828282;
    font-size: 14px;
    line-height: 17px;

    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      visibility: visible;
    }
  }
}

.main-navigation__item {
  &:last-child > .main-navigation__link::after {
    display: none;
  }
}

.main-navigation__link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  display: block;
  position: relative;
  padding-bottom: 5px;
  padding-top: 4px;

  &::after {
    position: absolute;
    content: "";
    width: 12px;
    height: 1px;
    bottom: 0;
    left: 50%;
    margin-left: -6px;
    background: $green;
  }


  &:hover {
    color: $text-green;
  }

  &--current {
    cursor: auto;
    color: $text-green;
  }
}

.main-navigation:focus-within .main-navigation__list--level1 {
  visibility: visible;
}

.main-navigation__link:focus:not([href]) + .main-navigation__list--level2 {
  visibility: visible;
}

@media (min-width: $ipad-width) {
  .main-navigation {
    width: 100%;
  }

  .main-navigation__item:focus-within .main-navigation__list {
    visibility: visible;
  }

  .main-navigation__item {
    position: relative;
    padding-left: 23px;
    padding-right: 23px;

    &:hover .main-navigation__list--level2 {
      visibility: visible;
    }
  }

  .main-navigation__list {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .main-navigation__list--level2 {
    top: 0;
    right: 100%;
    border: none;
  }
}

// Стиоли под десктоп
@media (min-width: 1150px) {
  .main-navigation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 796px;
    border-left: 1px solid $green;
    padding: 5px 3px 0;
  }

  .main-navigation__item {
    padding: 9px 0;

    .main-navigation__link {
      border-right: 1px solid $green;
    }

    &:last-child {
      .main-navigation__list--level2 {
        left: auto;
        right: 0;
      }

      .main-navigation__link {
        border: none;
      }
    }

    &:hover .main-navigation__list--level2 {
      visibility: visible;
    }
  }

  .main-navigation__list {
    width: auto;
    justify-content: center;
    font-size: 13px;
    line-height: 12px;

    &--level1 {
      position: static;
      visibility: visible;
      box-shadow: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &--level2 {
      top: 100%;
      left: 0;
      right: auto;
      font-size: 14px;
      line-height: 17px;

      .main-navigation__link {
        border: none;
        padding-bottom: 5px;
        padding-top: 4px;

        &::after {
          display: block;
        }
      }
    }
  }

  .main-navigation__link {
    padding: 0 9px;
    padding-right: 10px;

    &::after {
      display: none;
    }

    &--current {
      color: $text-green;
    }
  }

  .main-navigation__list--level2 .main-navigation__item {
    padding: 0 23px;
  }
}

@media (max-width: $desktop-width - 1px) {
  .main-navigation {
    &.nojs {
      width: 100%;
    }

    &.nojs .main-navigation__list {
      position: static;
      display: block;
      visibility: visible;
      width: 100%;
    }
  }
}
