.news-block__title {
  margin-bottom: 10px;
}

.news-block__list {
  margin: 0;
  list-style: none;
  padding-left: 0;
}

.news-block__item {
  text-indent: 15px;
  margin-bottom: 15px;
}

.news-block__subtitle {
  font-weight: inherit;
  font-size: inherit;
}

.news-block__date,
.news-block__subtitle,
.news-block__text {
  margin: 0;
  word-spacing: 1px;
}

@media (min-width: $ipad-width) {
  .news-block__date,
  .news-block__subtitle,
  .news-block__text {
    word-spacing: 0;
  }

  .news-block__item {
    margin-bottom: 21px;
  }
}

@media (min-width: $desktop-width) {
  .news-block__title {
    margin-top: 10px;
  }
}
