.printer-main {
  padding: 0 17px;
}

.printer-main__video {
  width: 260px;
  height: 145px;
}

.printer-main h2 {
  font-weight: bold;
  margin-bottom: 20px;
}

.printer-main p {
  margin-top: 20px;
  margin-bottom: 0;
}


@media (min-width: $ipad-width) {
  .printer-main {
    padding: 0 22px;
  }

  .printer-main h2 {
    margin-left: 2px;
  }

  .printer-main__video {
    min-width: 672px;
    min-height: 373px;
  }
}

@media (min-width: $desktop-width) {
  .printer-main {
    padding: 0 6px;
  }

  .printer-main h2 {
    margin-left: 22px;
  }

  .printer-main p {
    padding: 0 21px;
  }

  .printer-main__video {
    min-width: 690px;
    min-height: 383px;
  }
}
