/* .description__title {
  color: $text-green;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
*/

.description__text {
  text-indent: 23px;
  margin-top: 0;
  margin-bottom: 0;
  word-spacing: -1px;
}

.description__video {
  margin: 0;
  margin-top: 25px;
}

@media (min-width: $ipad-width) {
  .description__text {
    text-indent: 20px;
    word-spacing: normal;

    &:nth-of-type(2) {
      text-indent: 58px;
    }
  }

  .description__video {
    margin-top: 20px;
  }
}

@media (min-width: $desktop-width) {
  .description__text {
    text-indent: 44px;

    &:nth-of-type(2) {
      text-indent: 44px;
    }
  }
}
