.breadcrumbs {
  min-width: $iphone-width;
  display: block;
  background: $dark-bg;
  color: $text-white;

  &--light-bg {
    background: none;
    color: #000000;
  }

  &--desktop-only {
    display: none;
  }
}

.breadcrumbs__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  list-style: none;
  font-size: 10px;
  line-height: 12px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 7px;
}

.breadcrumbs__item {
  position: relative;
  padding-left: 4px;

  &:not(:last-child)::after {
    // position: absolute;
    content: " >";
    width: 15px;
    height: 20px;
    top: 0;
    right: 0;
  }
}

.breadcrumbs__link {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }

  &--current {
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }
}

// Стили под планшет
@media (min-width: $ipad-width) {
  .breadcrumbs__list {
    font-size: 17px;
    line-height: 21px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

// Стили под десктоп
@media (min-width: 1150px) {
  .breadcrumbs {
    display: block;
    padding-top: 8px;
    padding-bottom: 10px;
    // padding-left: 30px;
  }

  .breadcrumbs__list {
    width: 1150px;
    margin: 0 auto;
    padding-left: 22px;
    padding-right: 22px;
    font-size: 17px;
    line-height: 21px;
  }

  .breadcrumbs__item {
    position: relative;
    padding-left: 4px;

    &:not(:last-child)::after {
      content: " >";
      width: 15px;
      height: 20px;
      top: 0;
      right: 0;
    }
  }
}
