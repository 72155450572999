.content-block {
  min-width: $iphone-width;
  padding: 0 15px;
}

.content-block h1,
.content-block h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  color: $text-green;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.content-block p,
.content-block ul {
  margin-top: 0;
  margin-bottom: 12px;
  text-indent: 15px;
  font-size: 12px;
  line-height: 15px;
  text-align: justify;

  &.no-break {
    margin-bottom: 0;
  }
}

@media (min-width: $ipad-width) {
  .content-block h1,
  .content-block h2 {
    font-size: 26px;
    line-height: 32px;
  }

  .content-block p,
  .content-block ul {
    font-size: 14px;
    line-height: 17px;
  }
}

@media (min-width: $desktop-width) {
  .content-block {
    padding: 0;
  }
}
