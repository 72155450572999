.feedback {
  margin-bottom: 40px;
}

.feedback__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  // width: $iphone-width;
  min-width: $iphone-width;
}

.feedback__heading {
  margin: 0;
  // margin-top: 40px;
  margin-top: 25px;
  margin-bottom: 15px;
  padding: 0 12px;
  color: $text-green;
  font-size: 20px;
  line-height: 24px;
}

.feedback__form {
  display: flex;
  flex-direction: column;
  width: 100%;

  max-width: 640px;
}

.feedback__field {
  height: 48px;
  margin-top: 5px;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
  border: 1px solid $dark-border;
  border-radius: 0;
  padding: 3px 2px;

  &--comment {
    height: auto;
    min-height: 120px;
  }

  &::placeholder {
    font-size: 14px;
    line-height: 17px;
    color: #bdbdbd;
    opacity: 1;
  }

  &:focus {
    border-radius: 0;
  }

  &:invalid:not(:placeholder-shown) {
    border: 1px solid #f2994a;
  }
}

.feedback__submit-btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 52px;
  margin-top: 20px;
  padding: 10px 15px;
  background: $light-gray;
  border-radius: 0 30px 30px 0;
}

.feedback__submit-btn {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 7px;
  background: $green-bg;
  color: $text-white;
  text-transform: uppercase;
  text-align: center;
  border: none;
  outline: none;
  box-shadow: none;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
}

.feedback__popup {
  position: absolute;
  top: 156px;
  left: 50%;
  width: 290px;
  min-height: 170px;
  margin-left: -145px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  line-height: 29px;

  &--success {
    background-image: url("../img/success_icon.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }

  &--hidden {
    display: none;
  }

  p {
    margin: 20px 0;
    text-align: center;
  }
}

.feedback__error {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  background: url("../img/error_icon.svg");
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  min-height: 25px;
  margin-top: 0;

  &--captcha_code {
    margin-left: 0;
    margin-top: 15px;
    margin-bottom: 0;
  }
}

// Стили под планшет
@media (min-width: $ipad-width) {
  .feedback__wrapper {
    // width: $ipad-width;
    min-width: $ipad-width;
  }

  .feedback__heading {
    margin-top: 40px;
    font-size: 26px;
    line-height: 32px;
  }

  .feedback__form {
    width: 640px;
  }

  .feedback__field {
    padding: 2px 6px;
    margin-left: 13px;
    margin-right: 13px;
  }

  .feedback__submit-btn-wrapper {
    margin-right: 13px;
    padding-right: 10px;
  }

  .feedback__popup {
    width: 360px;
    height: 170px;
    top: 190px;
    margin-left: -180px;
  }
}

// Стили под десктоп
@media (min-width: 1150px) {
  .feedback {
    margin-bottom: 40px;
  }

  .feedback__wrapper {
    width: 100%;
    align-items: center;
  }

  .feedback__form {
    align-self: flex-end;
    align-items: flex-end;
    transform: translateX(98px);
    width: 56%;
    max-width: none;
    position: relative;
    left: -50%;
  }

  .feedback__heading {
    // position: relative;
    width: 644px;
    margin-top: 15px;
    // padding-left: 41px;
  }

  .feedback__field,
  .feedback__fieldset--captcha {
    width: 614px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .feedback__submit-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    min-width: 795px;
  }

  .feedback__submit-btn {
    width: 604px;
  }

  .feedback__popup {
    top: 167px;
    margin-left: 125px;
  }

  .feedback__error {
    width: 614px;
  }
}
