@import "normalize.scss",
  "variables.scss",
  "blocks/page-header.scss",
  "blocks/page-footer.scss",
  "blocks/main-navigation.scss",
  "blocks/footer-contacts.scss",
  "blocks/legal-info.scss",
  "blocks/header-contacts.scss",
  "blocks/social-nets.scss",
  "blocks/simple-button.scss",
  "blocks/welcome-block.scss",
  "blocks/main-block.scss",
  "blocks/content-block.scss",
  "blocks/video-block.scss",
  "blocks/description.scss",
  "blocks/advantages.scss",
  "blocks/news-block.scss",
  "blocks/training-welcome.scss",
  "blocks/training-kit.scss",
  "blocks/component.scss",
  "blocks/motivation-block.scss",
  "blocks/feedback.scss",
  "blocks/breadcrumbs.scss",
  "blocks/gallery.scss",
  "blocks/to-top-btn.scss",
  "blocks/captcha.scss",
  "blocks/printer-welcome.scss",
  "blocks/printer-main.scss",
  "blocks/milling.scss",
  "blocks/laboratory.scss",
  "blocks/characteristics.scss",
  "blocks/resolution.scss",
  "blocks/equipment.scss",
  "blocks/document-link.scss",
  "blocks/documentation.scss",
  "blocks/price.scss",
  "blocks/price-welcome.scss",
  "blocks/production.scss",
  "blocks/item.scss",
  "blocks/usage.scss",
  "blocks/product-form.scss",
  "blocks/buy-welcome.scss",
  "blocks/form-block.scss",
  "blocks/accessories.scss",
  "blocks/accessories-item.scss",
  "blocks/item-card.scss",
  "blocks/item-card-welcome.scss",
  "blocks/contacts.scss",
  "blocks/contacts-block.scss",
  "blocks/not-found.scss",
  "blocks/models.scss",
  "blocks/model-card.scss",
  "blocks/model-gallery.scss",
  "blocks/about-skyone.scss",
  "blocks/about-skyone-item.scss",
  "blocks/about-articles.scss",
  "blocks/about-articles-item.scss",
  "blocks/article.scss",
  // "blocks/instagram.scss",
  // "blocks/instagram-item.scss",
  "blocks/video.scss",
  "blocks/example.scss",
  "blocks/video-list.scss";

html {
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  font-family: $font;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// .visually-hidden:not(:focus):not(:active)
.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;

  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

h1,
h2,
h3,
p,
a,
button {
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: $ipad-width) {
  .mobile-only {
    display: none;
  }
}
