.buy-welcome h1 {
  margin-bottom: 10px;
  font-weight: bold;
}

.buy-welcome p {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: $ipad-width) {
  .buy-welcome h1 {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .buy-welcome p {
    margin-bottom: 18px;
  }
}

@media (min-width: $desktop-width) {
  .buy-welcome__wrapper {
    width: $desktop-width;
    margin: 0 auto;
    padding: 0 27px;
    padding-right: 37px;
  }

  .buy-welcome h1 {
    margin-top: 28px;
    padding: 0 14px;
  }
}
