.to-top-btn {
  display: block;
  position: fixed;
  bottom: 105px;
  right: 0;
  // left: 50%;
  // transform: translateX(110px);
  width: 50px;
  height: 50px;
  background: rgba(136, 189, 47, 0.5);
  border-radius: 50%;

  &:hover,
  &:focus,
  &:active {
    background: rgba(136, 189, 47, 0.7);
    border: none;
    outline: none;
    box-shadow: none;
  }

  &::after,
  &::before {
    display: block;
    position: absolute;
    content: "";
    width: 10px;
    height: 2px;
    background: $button-color;
    border-radius: 1px;
    transform: rotate(45deg);
    top: 24px;
  }

  &::before {
    transform: rotate(-45deg);
    left: 18px;
  }

  &::after {
    left: 24px;
  }
}
/*
@media (min-width: $ipad-width) {
  .to-top-btn {
    bottom: 145px;
    // transform: translateX(273px);
    width: 111px;
    height: 111px;
    background: rgba(136, 189, 47, 0.5);
    border-radius: 50%;

    &::after,
    &::before {
      width: 30px;
      height: 6px;
      border-radius: 3px;
      transform: rotate(45deg);
      top: 51px;
      left: 0;
    }

    &::before {
      transform: rotate(-45deg);
      left: 31px;
    }

    &::after {
      left: 50px;
    }
  }
}

@media (min-width: 1150px) {
  .to-top-btn {
    // transform: translateX(460px);
    bottom: 140px;
  }
} */
