.about-articles-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  h2 {
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 14px;
    padding: 0 15px;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    // text-decoration: underline;
  }

  p {
    width: auto;
    margin: 0;
    padding-left: 10px;
    border-left: 1px solid $green;
    font-size: 12px;
    line-height: 15px;
    text-align: justify;
    text-indent: 15px;
  }
}

.about-articles-item__title-link {
  width: 100%;
  display: block;
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.about-articles-item__picture-wrapper {
  // width: 144px;
  // height: 181px;
  display: flex;
  justify-content: center;
  margin: 5px auto;
  margin-bottom: 17px;

  img {
    max-width: 100%;
    max-height: 100%;
    // position: relative;
    // right: -50%;
  }
}

.about-articles-item__text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
  min-width: 61%;
  padding: 0 15px;
}

.about-articles-item__button {
  min-width: 63%;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 18px;
  padding: 9px 40px;
}

@media (min-width: 470px) {
  .about-articles-item__text-wrapper {
    width: 320px;
  }
}

@media (min-width: $ipad-width) {
  .about-articles-item {
    position: relative;
    min-height: 197px;
    padding-left: 166px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;

    h2 {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 20px;
      margin-top: 0;
    }

    p {
      width: auto;
      padding-right: 0;
      font-size: 14px;
      line-height: 17px;
    }
  }

  .about-articles-item__picture-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 160px;
    align-items: center;
    justify-content: flex-end;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 197px;
    }
  }

  .about-articles-item__text-wrapper {
    justify-content: flex-start;
    width: auto;
  }

  .about-articles-item__button {
    min-width: 43%;
    margin-left: 0;
    margin-bottom: 0;
  }
}

@media (min-width: $desktop-width) {
  .about-articles-item {
    width: 554px;
    padding-left: 0;
    overflow: hidden;
    align-items: flex-end;
    justify-content: flex-start;

    p {
      padding-right: 0;
    }

    h2 {
      width: 380px;
      margin-right: 0;
      padding-left: 20px;
    }
  }


  /* h2 {
  } */

  .about-articles-item__picture-wrapper {
    left: 0;
    right: 0;
    margin-right: 0;
    margin-left: 0;
    width: 167px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  /* &:nth-child(2n-1) {
  } */

  /* &:nth-child(2n) {
    h2 {
      margin-left: 0;
      margin-right: auto;
      padding-right: 0;
    }

      .about-articles-item__picture-wrapper {
      left: 435px;
      right: auto;
      border-radius: 0;
      border-top-left-radius: 150px;
      border-bottom-left-radius: 150px;
      justify-content: flex-start;
      margin-left: 68px;
    }

    img {
      right: 0;
      left: -55%;
      transform: scale(-1, 1);
    }

    .about-articles-item__text-wrapper {
      width: 400px;
    }
  } */


  .about-articles-item__text-wrapper {
    width: 360px;
    min-width: auto;
    padding: 0;
  }
}
