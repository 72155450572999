.item-card-welcome {
  h3 {
    margin: 5px 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: $text-black;
  }
}

.item-card-welcome__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.item-card-welcome__heading {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 15px;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: $text-black;

  &--scara {
    margin-top: 22px;
    margin-bottom: 20px;
  }
}

.item-card-welcome__logo {
  width: 100%;
  margin-bottom: 14px;
  padding: 0 15px;
}

.item-card-welcome__picture-wrapper {
  margin-right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 195px;
  min-height: 177px;
  background: $light-bg;
  border-top-right-radius: 200px;
  border-bottom-right-radius: 200px;

  img {
    position: relative;
    right: -20px;
  }

  &--scara {
    img {
      right: -68px;
    }
  }
}

.item-card-welcome__text-wrapper {
  width: 100%;
  padding: 0 15px;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;

  p {
    margin: 0;
  }
}

.item-card-welcome__own-production {
  height: 36px;
  margin: 15px 0;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 18px;
  padding-top: 18px;
  padding-left: 25px;
  background-image: url("../img/own-production_icon.svg");
  background-repeat: no-repeat;
}

.item-card-welcome__characteristics {
  border-left: 2px solid $green;
  padding-left: 9px;
  padding-right: 15px;

  &--scara {
    margin-top: 20px;
    margin-bottom: 0;
  }

  h2 {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: $text-black;
  }

  ul {
    margin: 0;
    padding-left: 0;
    list-style: none;
  }

  p {
    text-align: justify;
  }
}

.item-card-welcome__button-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  background: $light-bg;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding: 12px 14px;
  width: 100%;
  margin-left: 40px;
}

.item-card-welcome__button {
  min-width: 182px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (min-width: 545px) {
  .item-card-welcome__picture-wrapper {
    &--scara {
      img {
        right: -50px;
      }
    }
  }

  .item-card-welcome__text-wrapper {
    width: $iphone-width;
    min-width: 58%;
  }

  .item-card-welcome__button-wrapper {
    margin-left: 0;
  }
}

@media (min-width: $ipad-width) {
  .item-card-welcome__wrapper {
    position: relative;
    padding-left: 385px;
    min-height: 364px;
    align-content: center;

    &--scara {
      min-height: 414px;
    }
  }

  .item-card-welcome__heading {
    font-size: 26px;
    line-height: 32px;

    &--engraving-kit {
      @media (max-width: 1149px) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &--scara {
      margin-bottom: 0;
    }
  }

  .item-card-welcome__logo {
    img {
      width: 180px;
      height: 32px;
    }

    &--skyone {
      img {
        width: 182px;
        height: 28px;
      }
    }
  }

  .item-card-welcome__picture-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 241px;
    // min-height: 364px;
    height: 100%;

    img {
      right: -65px;
      width: auto;
      height: auto;
    }

    &--printing-spray {
      img {
        right: -50%;
      }
    }

    &--scara {
      width: 270px;

      img {
        right: -100px;
      }
    }
  }

  .item-card-welcome__text-wrapper {
    width: 100%;
  }

  .item-card-welcome__own-production {
    margin-top: 0;
    padding-top: 14px;
    height: auto;
    padding-bottom: 14px;
    padding-left: 42px;
    background-size: contain;
    font-size: 17px;
    line-height: 21px;
  }

  .item-card-welcome__characteristics {
    &--scara {
      p {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}

@media (min-width: $desktop-width) {
  .item-card-welcome__wrapper {
    padding-left: 0;
    justify-content: flex-end;
    min-height: 414px;
    align-items: flex-start;
    // align-content: center;

    &--scara {
      min-height: 487px;

      .item-card-welcome__heading {
        margin-top: 41px;
        margin-bottom: 0;
        font-size: 36px;
        line-height: 44px;
      }
    }
  }

  .item-card-welcome__heading {
    width: 56.7%;
    margin-top: 0;
  }

  .item-card-welcome__logo {
    width: 56.7%;
    margin-top: 5px;
  }

  .item-card-welcome__text-wrapper {
    width: 50%;
    min-width: 56.7%;
  }

  .item-card-welcome__characteristics {
    &--scara {
      width: 643px;
      padding-left: 8px;
      text-indent: 15px;
      text-align: justify;

      p {
        font-size: 17px;
        line-height: 21px;
      }
    }
  }

  .item-card-welcome__own-production {
    padding-top: 18px;
    padding-bottom: 10px;
  }

  .item-card-welcome__picture-wrapper {
    left: -50%;
    width: 100%;
    transform: translateX(-306px);

    img {
      right: -90px;
    }

    &--printing-spray {
      img {
        right: -50%;
      }
    }

    &--scara {
      border-top-right-radius: 243.5px;
      border-bottom-right-radius: 243.5px;
      transform: translateX(-210px);
    }
  }
}
