.video-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.video-list__item {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

@media (min-width: $iphone-width) {
  .video-list__item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
