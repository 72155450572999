.gallery {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  height: 158px;
  margin: 0 auto;

  &--engraving-kit {
    height: auto;
    width: 100%;
    max-width: 372px;
    margin-top: 20px;

    .gallery__list {
      margin: 0 55px;
    }
  }

  &--nojs {
    height: auto;

    &.gallery--engraving-kit {
      height: auto;
    }

    .gallery__item {
      display: block;
    }

    .gallery__button {
      display: none;
    }
  }
}

.gallery__list {
  margin: 0 auto;
  list-style: none;
  padding-left: 0;
}

.gallery__item {
  display: none;

  &--current {
    display: block;
  }
}

.gallery__button {
  position: absolute;
  top: 50%;
  // top: 80px;
  margin-top: -25px;
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  background: rgba(189, 189, 189, 0.5);
  flex-shrink: 0;

  &--engraving-kit {
    // position: static;

    &.gallery__button--back {
      left: 0;
    }

    &.gallery__button--forward {
      right: 0;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background: rgba(130, 130, 130, 0.5);
    outline: none;
    box-shadow: none;
  }

  &:active:hover {
    padding-left: 8px;
    padding-right: 8px;
  }

  &::after {
    position: relative;
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    left: 50%;
    border-left: 2px solid $button-color;
    border-bottom: 2px solid $button-color;
  }

  &--back {
    left: 15px;

    &::after {
      transform: translateX(-50%) rotate(45deg);
    }
  }

  &--forward {
    right: 15px;

    &::after {
      transform: translateX(-50%) rotate(225deg);
    }
  }
}

.gallery__image {
  display: block;
  max-height: 158px;
  max-width: 288px;

  &--engraving-kit {
    max-height: none;
    max-width: 262px;
    width: 100%;
  }
}

@media (min-width: $ipad-width) {
  .gallery {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 266px;

    &--engraving-kit {
      max-width: 462px;
      width: 462px;
      height: 351px;

      .gallery__list {
        margin: 0 30px;
      }
    }
  }

  .gallery__button {
    position: static;
    /*  margin-top: 90px;
    margin-bottom: auto;
    margin-left: 27px;
    margin-right: 27px; */
    /* margin: 0 27px;
    padding: 0;
    width: 86px;
    height: 86px; */
    background: $gallery-btn-color;

    &:hover,
    &:focus,
    &:active {
      background: rgba(130, 130, 130, 1);
    }

    &--back {
      margin-left: auto;
    }

    &--forward {
      margin-right: auto;
    }

    /* &::after {
      width: 20px;
      height: 20px;
      border-left: 4px solid $button-color;
      border-bottom: 4px solid $button-color;
    }

    &--back {
      &::after {
        transform: translateX(-7px) rotate(45deg);
      }
    }

    &--forward {
      &::after {
        transform: translateX(-10px) rotate(225deg);
      }
    } */

    /*  &--engraving-kit {
      width: 50px;
      height: 50px;
      margin: 0;

      &::after {
        width: 10px;
        height: 10px;
        left: 50%;
        border-left: 2px solid $button-color;
        border-bottom: 2px solid $button-color;
      }

      &.gallery__button--back {
        &::after {
          transform: translateX(-50%) rotate(45deg);
        }
      }

      &.gallery__button--forward {
        &::after {
          transform: translateX(-50%) rotate(225deg);
        }
      }
    } */
  }

  .gallery__list {
    margin: 0 30px;
  }

  .gallery__image {
    max-height: 266px;
    max-width: 484px;
    height: auto;

    &--demonstration {
      width: 484px;
    }

    &--engraving-kit {
      max-height: 351px;
    }
  }
}

@media (min-width: 1150px) {
  .gallery {
    margin: 0 auto;
    width: 1150px;
    height: 410px;
    min-height: 370px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    &--engraving-kit {
      height: 351px;
      width: 425px;
      margin-right: 0;
    }

    &--nojs {
      width: 100%;

      .gallery__list {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      .gallery__item {
        margin: 5px;
      }
    }
  }

  .gallery__button {
    // margin-top: 163px;

    &--back {
      margin-left: 85px;
    }

    &--forward {
      margin-right: 85px;
    }

    &--engraving-kit {
      margin: 0;
    }
  }

  .gallery__image {
    max-width: 746px;
    max-height: 410px;

    &--demonstration {
      width: 746px;
    }

    &--engraving-kit {
      max-height: 351px;
      max-width: 262px;
    }
  }
}
