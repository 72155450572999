.about-skyone-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  h2 {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 14px;
    padding: 0 15px;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    // text-decoration: underline;
  }

  p {
    width: auto;
    margin: 0;
    padding-left: 10px;
    border-left: 1px solid $green;
    font-size: 12px;
    line-height: 15px;
    text-align: justify;
    text-indent: 15px;
  }
}

.about-skyone-item__title-link {
  width: 100%;
  display: block;
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.about-skyone-item__picture-wrapper {
  width: 144px;
  height: 181px;
  margin-right: 60px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: $light-bg;
  border-top-right-radius: 150px;
  border-bottom-right-radius: 150px;

  img {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    right: -50%;
  }
}

.about-skyone-item__elements-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 320px;
  padding: 0 15px;
}

.about-skyone-item__link {
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

@media (min-width: 524px) {
  .about-skyone-item__elements-wrapper {
    width: 320px;
    min-width: 61%;
  }
}

@media (min-width: $ipad-width) {
  .about-skyone-item {
    position: relative;
    min-height: 271px;
    padding-left: 312px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;

    h2 {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 20px;
    }

    p {
      width: auto;
      padding-right: 48px;
      font-size: 14px;
      line-height: 17px;
    }
  }

  .about-skyone-item__picture-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 216px;

    img {
      width: auto;
      height: auto;
    }
  }

  .about-skyone-item__elements-wrapper {
    justify-content: flex-start;
    width: auto;
  }

  .about-skyone-item__button {
    min-width: 43%;
    margin-left: 0;
    margin-bottom: 0;
  }
}

@media (min-width: $desktop-width) {
  .about-skyone-item {
    width: 50%;
    padding-left: 0;
    overflow: hidden;

    p {
      padding-right: 8px;
    }

    h2 {
      width: 412px;
      margin-right: 0;
    }

    &:nth-child(2n-1) {
      align-items: flex-end;

      h2 {
        padding-left: 20px;
      }

      .about-skyone-item__picture-wrapper {
        left: auto;
        right: 435px;
        margin-right: 68px;
      }

      img {
        right: -56%;
      }
    }

    &:nth-child(2n) {
      h2 {
        margin-left: 0;
        margin-right: auto;
        padding-right: 0;
      }

      .about-skyone-item__picture-wrapper {
        left: 435px;
        right: auto;
        border-radius: 0;
        border-top-left-radius: 150px;
        border-bottom-left-radius: 150px;
        justify-content: flex-start;
        margin-left: 68px;
      }

      img {
        right: 0;
        left: -55%;
        transform: scale(-1, 1);
      }

      .about-skyone-item__elements-wrapper {
        width: 400px;
      }
    }
  }

  .about-skyone-item__elements-wrapper {
    width: 408px;
    min-width: auto;
  }

  .about-skyone-item__picture-wrapper {
    width: 100%;

    img {
      max-width: 200px;
    }
  }
}
