.price {
  padding: 0;
}

.price h1,
.price h2 {
  margin-bottom: 20px;
}

@media (min-width: $ipad-width) {
  .price h1 {
    margin-bottom: 10px;
  }
}
