.social-nets {
  width: 320px;
  padding: 0 10px;
}

.social-nets__lead {
  text-align: center;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  margin-top: 10px;
  margin-bottom: 0;
}

.social-nets__list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 10px;
  list-style: none;
  padding-left: 0;
}

.social-nets__item {
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
}

.social-nets__link {
  margin-top: 0;
  display: block;
  width: 27px;
  height: 27px;
  color: #4f4f4f;

  &:hover,
  &:focus,
  &:active {
    color: $green;
  }
}


@media (min-width: $ipad-width) {
  .social-nets {
    width: 268px;
    flex-shrink: 0;
    padding-left: 44px;
  }

  .social-nets__lead {
    display: none;
  }
}

@media (min-width: 1150px) {
  .social-nets {
    border-left: 1px solid $green;
    padding-left: 10px;
    align-items: flex-start;
  }

  .social-nets__lead {
    display: block;
    margin-top: 0;
    text-align: left;
    font-size: 14px;
    line-height: 14px;
  }

  .social-nets__list {
    justify-content: flex-start;
    margin-top: 13px;
    margin-bottom: 0;
  }

  .social-nets__link {
    background-size: cover;
    color: $text-white;
  }
}
