.resolution ul {
  margin-top: 0;
  list-style-position: inside;
  list-style-type: "— ";
  padding-left: 0;
  text-indent: 15px;
}

.resolution img {
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}

@media (min-width: $ipad-width) {
  .resolution img {
    width: 281px;
    height: 296px;
    margin-top: 10px;
    margin-left: 73px;
  }
}

@media (min-width: $desktop-width) {
  .resolution img {
    margin-left: 100px;
  }
}
