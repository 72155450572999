.form-block {
  margin-top: 10px;
  min-width: $iphone-width;
}

.form-block__gallery {
  margin-top: 20px;
}

@media (min-width: $ipad-width) {
  .form-block {
    margin-top: 20px;
  }
}

@media (min-width: $desktop-width) {
  .form-block {
    margin-top: 10px;
  }
}
