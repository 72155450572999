.documentation {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.documentation__item {
  margin-top: 10px;
}
