.contacts {
  display: flex;
  flex-direction: column;
  min-width: $iphone-width;
  margin-top: 25px;
  margin-bottom: 15px;
}

@media (min-width: $ipad-width) {
  .contacts {
    margin-top: 35px;
  }
}

@media (min-width: $desktop-width) {
  .contacts {
    margin-top: 35px;
  }
}
