.welcome-block {
  background: $welcome-bg;
  color: $welcome-color;
  font-family: $font;
}

.welcome-block__wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // align-items: center;
  // padding-left: 26px;
  // padding-right: 15px;
  padding-top: 18px;
  margin: 0 auto;

  // width: $iphone-width;
  // justify-content: space-between;
  justify-content: flex-start;
  min-width: $iphone-width;
}

.welcome-block__heading {
  width: 100%;
  margin-bottom: 15px;
  padding: 0 28px;
}

.welcome-block__title {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
}

.welcome-block__note {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
}

.welcome-block__text-wrapper {
  padding-right: 14px;

  min-width: 50%;
  margin-left: 12%;
}

.welcome-block__advantages {
  list-style: none;
  border-left: 1px solid $green;
  padding-left: 5px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  width: 150px;
  margin-top: 62px;
  margin-bottom: 80px;
}

.welcome-block__advantage {
  margin-bottom: 3px;
}

.welcome-block__description {
  display: none;
}

.welcome-block__button-box {
  display: flex;
  justify-content: flex-start;
  width: 305px;
  margin-top: 5px;
  margin-bottom: 20px;
  margin-left: auto;
  background: #f2f2f2;
  padding-left: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 30px 0 0 30px;

  min-width: 95%;
}

.welcome-block__button {
  display: block;
  margin-right: auto;
  padding: 9px 40px;
  // order: 1;
  position: relative;

  // min-width: 182px;
  min-width: 65%;
  // text-decoration: none;
  // color: inherit;
  // text-transform: uppercase;
  // font-weight: bold;
  // font-size: 12px;
  // line-height: 15px;
  // background: $green;
  // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  // border-radius: 30px;

  /* &:hover {
    background: #333333;
  } */
}

.welcome-block__photo {
  // position: absolute;
  // top: 73px;
  // left: 0;
  width: 117px;
  height: 182px;
  background: #e0e0e0;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;

  min-width: 30%;
  display: flex;
  justify-content: flex-end;
}

.welcome-block__image {
  position: relative;
  top: -65px;
  // left: 38px;
  // width: 224px;
  height: auto;

  right: -135px;
}

@media (min-width: $ipad-width) {
  .welcome-block__wrapper {
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    min-height: 346px;
    padding-left: 350px;
    padding-top: 30px;

    // width: $ipad-width;
    min-width: $ipad-width;
  }

  .welcome-block__title {
    font-size: 18px;
    line-height: 19px;
  }

  .welcome-block__heading {
    margin-bottom: 18px;
    padding: 0;
    padding-right: 14px;
  }

  .welcome-block__text-wrapper {
    margin-left: 0;
  }

  .welcome-block__advantages {
    width: 100%;
    font-size: 12px;
    line-height: 15px;
    border-width: 2px;
    margin: 0;
  }

  .welcome-block__advantage {
    margin-bottom: 2px;
  }

  .welcome-block__description {
    display: block;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    border-left: 2px solid $green;
    margin-top: 15px;
    margin-bottom: 22px;
    padding-left: 5px;

    & b {
      font-weight: bold;
    }
  }

  .welcome-block__description-txt {
    margin: 0;
  }

  .welcome-block__button-box {
    width: 100%;
    margin-top: 0;
    padding-left: 19px;
    padding-right: 19px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .welcome-block__button {
    order: 0;

    min-width: 48%;
  }

  .welcome-block__photo {
    position: absolute;
    top: 30px;
    left: 0;
    width: 288px;
    height: 284px;
    border-top-right-radius: 242px;
    border-bottom-right-radius: 242px;

    min-width: auto;
    display: block;
  }

  .welcome-block__image {
    top: -67px;
    left: 17px;
    width: 387px;
  }
}

@media (min-width: $desktop-width) {
  .welcome-block {
    position: relative;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  .welcome-block__wrapper {
    position: static;
    width: 59%;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 55px;
    padding-left: 0;
    padding-right: 0;
    min-height: 536px;
    margin-left: 41%;
    margin-right: 0;
  }

  .welcome-block__heading {
    max-width: 700px;
    margin-bottom: 44px;
  }

  .welcome-block__title {
    font-size: 36px;
    line-height: 44px;
  }

  .welcome-block__note {
    font-size: 20px;
    line-height: 24px;
  }

  .welcome-block__advantages {
    padding-top: 2px;
    padding-left: 10px;
    font-size: 18px;
    line-height: 22px;
  }

  .welcome-block__advantage {
    margin-bottom: 4px;
  }

  .welcome-block__description {
    margin-top: 20px;
    margin-bottom: 53px;
    padding-top: 5px;
    width: 600px;
    // padding-right: 100px;
    padding-left: 10px;
    font-size: 15px;
    line-height: 18px;
  }

  .welcome-block__photo {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 34%;
    height: 487px;
    top: 25px;
  }

  .welcome-block__image {
    width: 459px;
    top: -37px;
    left: 130px;
  }

  .welcome-block__button-box {
    padding-left: 25px;
  }

  .welcome-block__button {
    min-width: 182px;
  }
}
