.model-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: $iphone-width;
  align-items: center;
}

.model-card__wrapper {
  width: 100%;
}

.model-card__heading-wrapper {
  margin-top: 10px;
  margin-right: 15px;
  background: $light-bg;
  color: $text-black;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding-left: 15px;
  padding-right: 50px;
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 81%;
}

.model-card__heading {
  overflow: hidden;
  margin: 0;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
}

.model-card__gallery {
  width: $iphone-width;
  margin: 0 auto;
  margin-top: 20px;
}

.model-card__text-wrapper {
  border-left: 1px solid $green;
  padding-left: 9px;
  margin: 20px 15px;
  color: #434242;

  p {
    font-size: 12px;
    line-height: 15px;
    max-width: 100%;
    margin: 0;
  }
}

.model-card__button-wrapper {
  // margin-left: 15px;
  background: $light-bg;
  color: $text-black;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding: 12px 14px;
  display: flex;
  justify-content: flex-start;
  margin-left: auto;
  margin-bottom: 30px;
  margin-top: 20px;
  width: calc(50% + 105px);
}

.model-card__button {
  padding: 9px 40px;
  min-width: 182px;
}

@media (min-width: 640px) {
  .model-card {
    align-items: stretch;
    margin-bottom: 30px;
  }

  .model-card__wrapper {
    width: 50%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  .model-card__text-wrapper {
    margin-top: auto;
    margin-bottom: auto;
  }

  .model-card__button-wrapper {
    width: 100%;
    margin-bottom: 0;
    margin-left: 35px;
  }
}

@media (min-width: $ipad-width) {
  .model-card {
    margin-bottom: 17px;
  }

  .model-card__heading-wrapper {
    max-width: 100%;
    margin-right: 15px;
    margin-top: 23px;
    padding-top: 17px;
    padding-bottom: 16px;
    padding-left: 70px;
  }

  .model-card__heading {
    font-size: 20px;
    line-height: 24px;
  }

  .model-card__wrapper {
    overflow: hidden;
  }

  .model-card__text-wrapper {
    p {
      font-size: 17px;
      line-height: 21px;
    }
  }

  .model-card__gallery {
    width: 50%;
  }

  .model-card__button-wrapper {
    padding: 12px 16px;
    margin-bottom: 8px;
    margin-left: 40px;
  }
}

@media (min-width: $desktop-width) {
  .model-card {
    margin-bottom: 5px;
  }

  .model-card__wrapper {
    width: 657px;
    flex-grow: 1;
    margin-right: auto;
  }

  .model-card__heading-wrapper {
    margin-right: 0;
    margin-top: 34px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    background: none;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;

    &::before {
      display: block;
      content: "";
      width: calc(50% - 1150px / 2);
      background: #f2f2f2;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }

  .model-card__heading {
    text-align: left;
    width: $desktop-width;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .model-card__heading-txt {
    min-width: 250px;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 20px;
    padding-right: 20px;
    background: #f2f2f2;
    display: block;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .model-card__gallery {
    margin-left: 0;
    margin-right: 0;
  }

  .model-card__text-wrapper {
    width: 640px;
    border-left-width: 2px;
    padding-right: 75px;
    margin-left: 0;

    p {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .model-card__button-wrapper {
    padding: 14px 18px;
    margin-left: 0;
    margin-bottom: 12px;
  }
}
