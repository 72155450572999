.motivation-block {
  margin-bottom: 15px;
}

.motivation-block__wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  // width: $iphone-width;
  min-width: $iphone-width;
}

.motivation-block__lead-wrapper {
  margin-top: 24px;
  align-self: flex-start;
}

.motivation-block__lead {
  padding: 6px 20px;
  max-width: 246px;
  margin-top: 0;
  background: $green-bg;
  border-radius: 0 30px 30px 0;
  color: $text-white;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.motivation-block__gallery {
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  max-width: 450px;
}

.motivation-block__button {
  display: none;
}

// Стили под планшет
@media (min-width: $ipad-width) {
  .motivation-block {
    margin-bottom: 0;
  }

  .motivation-block__wrapper {
    // width: $ipad-width;
    min-width: $ipad-width;
  }

  .motivation-block__lead-wrapper {
    margin-top: 3px;
  }

  .motivation-block__lead {
    margin-bottom: 40px;
    max-width: none;
    width: 752px;
    padding: 8px 15px 11px;
    font-size: 36px;
    line-height: 44px;
  }

  .motivation-block__gallery {
    margin: 0;
    max-width: none;
  }
}

// Стили под десктоп
@media (min-width: 1150px) {
  .motivation-block__wrapper {
    width: 100%;
  }

  .motivation-block__lead-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 62.5%;
    min-width: 900px;
    margin-bottom: 40px;
    background: $green-bg;
    border-radius: 0 30px 30px 0;

    align-self: flex-end;
    transform: translateX(180px);
    position: relative;
    left: -50%;
  }

  .motivation-block__lead {
    width: 755px;
    background: none;
    margin: 0;
    padding-left: 18px;
    padding-right: 18px;
    text-align: left;
  }
}
