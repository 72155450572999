.item {
  width: 305px;
  border-top-right-radius: 243.5px;
  border-bottom-right-radius: 243.5px;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 28px;
  padding-right: 60px;
  background: $light-bg;
  color: $text-black;
  font-size: 20px;
  line-height: 24px;
}

.item__name {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.item__price {
  margin-top: 8px;
  margin-bottom: 10px;
}

.item__buy-btn {
  padding-top: 9px;
  padding-bottom: 8px;
  min-width: 182px;
  font-weight: 700;
}

@media (min-width: $ipad-width) {
  .item {
    width: 753px;
    min-width: 90%;
    min-height: auto;
    padding: 4px 6px 4px 15px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  .item__name {
    max-width: 50%;
    margin-right: auto;
  }

  .item__price {
    margin: 0;
    width: 125px;
  }

  .item__buy-btn {
    margin-left: 40px;
  }
}

@media (min-width: $desktop-width) {
  .item {
    width: 76%;
    justify-content: flex-end;
  }

  .item__name {
    margin-right: 0;
    width: 440px;
  }

  .item__price {
    width: 229px;
    margin-left: 35px;
  }

  .item__buy-btn {
    margin-left: 35px;
  }
}
