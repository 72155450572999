.legal-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 227px;
  flex-shrink: 0;
  text-align: right;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
}

.legal-info__element {
  font-style: normal;
  margin-top: 0;
  margin-bottom: 0;

  &--address {
    width: 150px;
  }
}

@media (min-width: $ipad-width) {
  .legal-info {
    padding-top: 3px;
    width: 315px;
    font-size: 12px;
    line-height: 15px;
  }

  .legal-info__element {
    &--address {
      width: auto;
    }
  }
}

@media (min-width: 1150px) {
  .legal-info {
    width: 390px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 15px;
    line-height: 18px;
  }
}
