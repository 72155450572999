.production {
  min-width: $iphone-width;
}

.production h2 {
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  color: $green;
  padding: 0 15px;
}

.production__price-list {
  padding-left: 0;
  list-style: none;
  margin-bottom: 20px;
}

.production__item {
  min-width: 50%;
  margin-bottom: 10px;
}


@media (min-width: $ipad-width) {
  .production__item {
    width: 100%;
    margin-bottom: 12px;
  }
}

@media (min-width: $desktop-width) {
  .production h2 {
    width: 1150px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 23px;
  }

  .production__description {
    width: $desktop-width;
    margin: 0 auto;
    padding: 0 23px;

    p {
      text-indent: 20px;
    }
  }

  .production__price-list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .production__item {
    position: relative;
    width: 76%;
    left: -50%;
    transform: translateX(375px);
  }
}
