/* .milling {
  padding-left: 5px;
  padding-right: 5px;
} */

.milling p {
  // width: 280px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.milling__pictures-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 6px;

  img {
    margin-top: 12px;
  }
}

.milling ul {
  margin-top: 0;
  list-style-position: inside;
  list-style-type: none;
  padding-left: 0;
  text-indent: 15px;
  margin-bottom: 0;
}

@media (min-width: $ipad-width) {
  .milling__pictures-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;

    > * {
      margin-left: 0;
      margin-right: auto;
    }

    > *:nth-child(2n-1) {
      margin-right: 16px;
    }
  }

  .milling p {
    margin-top: 21px;
  }

  .milling img {
    width: 354px;
    height: auto;
  }
}

@media (min-width: $desktop-width) {
  .milling img {
    width: 500px;
  }

  .milling__pictures-wrapper {
    width: 100%;

    > * {
      margin: 0;
    }

    > *:nth-child(2n-1):last-child {
      margin-right: auto;
    }
  }
}
