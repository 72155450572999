.accessories {
  min-width: $iphone-width;
}

.accessories h1 {
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
  padding: 0 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: $green;
}

.accessories__items-list {
  margin-top: 10px;
  padding-left: 0;
  list-style: none;
}

@media (min-width: $ipad-width) {
  .accessories h1 {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
  }
}

@media (min-width: $desktop-width) {
  .accessories h1 {
    width: 1150px;
    padding-left: 5px;
    margin-top: 30px;
  }

  .accessories__items-list {
    display: flex;
    flex-wrap: wrap;
  }
}
