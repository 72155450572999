.product-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 17px;
  line-height: 21px;
}

.product-form__fieldset {
  border: none;
  padding: 0 15px;

  &--price {
    padding: 0;
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
  }
}

.product-form__field-wrapper {
  &--price {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    background: $light-bg;
  }
}

.product-form__field-name {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: $green;

  &--price {
    margin-top: 0;
    margin-bottom: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 14px;
    padding-right: 14px;
  }

  /* &--color {
    margin-bottom: 14px;
  } */
}

.product-form__field {
  &--price {
    padding: 7px 18px;
    // background-color: transparent;
    // padding-left: 18px;
    padding-right: 6px;
    border: none;
    box-shadow: none;
    color: $green;
    background: none;
    width: 155px;
  }
}

.product-form__fields-container {
  min-width: $iphone-width;
  width: 100%;
  padding: 0;
}

.product-form__list {
  margin-top: 0;
  margin-bottom: 0;
  border-left: 1px solid $green;
  list-style-type: "- ";
  list-style-position: inside;
  padding-left: 12px;

  &--inputs {
    list-style: none;
    list-style-position: outside;
  }

  &--level2 {
    border: none;
    margin-top: 8px;
    padding-left: 30px;
    margin-bottom: 14px;
  }

  li {
    line-height: 21px;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--characteristics {
    li {
      // line-height: inherit;
      margin-bottom: 0;
    }
  }
}

.product-form__list--additional {
  li {
    margin-bottom: 10px;
  }
}

.product-form img {
  display: block;
  max-width: 288px;
  height: auto;
  margin-top: 13px;
  margin-left: 16px;
}

.product-form__option-label {
  display: block;
  position: relative;
  padding-left: 30px;

  &:hover {
    opacity: 0.5;
  }

  &::before {
    position: absolute;
    content: "";
    width: 17px;
    height: 17px;
    border-radius: 50%;
    border: 1px solid $green;
    left: 0;
    top: 50%;
    margin-top: -8px;
  }
}

.product-form__checkbox,
.product-form__radio {
  &:checked {
    ~ .product-form__option-label::after {
      position: absolute;
      content: "";
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: $green;
      left: 4px;
      top: 50%;
      margin-top: -4px;
    }
  }

  &:focus {
    ~ .product-form__option-label {
      opacity: 0.5;
    }
  }
}

@media (min-width: $ipad-width) {
  .product-form {
    position: relative;
    padding-right: 50%;
  }

  .product-form__fields-container {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0;

    &--sky-one {
      top: -26px;
    }
  }

  /* .product-form__fieldset {
    &--characteristics {
      margin-top: 61px;
      padding-left: 0;
    }
  } */

  .product-form__fieldset {
    padding-right: 0;

    &--characteristics {
      margin-top: auto;
      margin-bottom: 170px;
    }
  }

  .product-form__field-name {
    font-size: 26px;
    line-height: 32px;
    margin-top: 8px;
    margin-bottom: 20px;

    &--price {
      padding: 0 14px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .product-form__field {
    &--price {
      padding: 8px 18px;
      width: 242px;
    }
  }

  .product-form__list {
    margin-bottom: 14px;

    &--level2 {
      margin-bottom: 14px;
    }
  }

  .product-form img {
    width: auto;
    height: auto;
    max-width: 100%;
    margin-top: 0;
    // margin-bottom: 60px;
    margin-left: 15px;

    &.product-form__img--sky-one {
      width: 359px;
      height: 462px;
      // margin: 0;
    }
  }
}

@media (min-width: $desktop-width) {
  .product-form {
    width: 48%;
    padding-right: 0;
  }

  .product-form__fields-container {
    width: 545px;
    right: auto;
    left: 100%;

    &--sky-one {
      top: 0;
      margin-top: 18px;
      // padding-left: 14px;
    }
  }

  .product-form__fieldset {
    width: 545px;
    margin-left: auto;
    padding-left: 42px;

    &--characteristics {
      // padding-left: 0;
      // margin-top: 33px;
      // width: 560px;
      margin-bottom: 214px;
    }
  }

  .product-form__field-name {
    margin-top: 11px;
  }

  .product-form__list {
    border-width: 2px;
    margin-left: 2px;
    margin-bottom: 11px;

    &--level2 {
      margin-bottom: 14px;
    }
  }

  .product-form__fieldset--price {
    width: 100%;
    align-items: flex-end;
    padding-left: 0;

    .product-form__field-name {
      width: 545px;
      margin-top: 10px;
      margin-bottom: 9px;
      border-radius: 0;
      background: none;
      padding-left: 42px;
    }

    .product-form__field {
      width: 234px;
      padding: 6px 10px;
      background: none;
      border-radius: none;
    }

    .product-form__field-wrapper {
      width: 100%;
      // position: relative;
      margin-right: 278px;
      // transform: translateX(-33px);
      display: flex;
      justify-content: flex-end;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      background: $light-bg;
    }
  }

  .product-form img {
    // margin-bottom: 33px;
    margin-left: 45px;
    // padding-left: 42px;

    &.product-form__img--sky-one {
      width: 400px;
      height: 462px;
      // margin-bottom: 33px;
      margin-left: 45px;
      // margin: 0;
    }
  }
}
