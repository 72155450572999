.model-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.model-gallery__image-wrapper {
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}

.model-gallery__carousel {
  display: flex;
  width: $iphone-width;
  justify-content: center;
  margin-top: 8px;
}

.model-gallery__preview {
  display: flex;
  width: 185px;
  justify-content: space-between;
  overflow: hidden;

  img {
    cursor: pointer;
    display: block;
    width: 56px;
    height: 56px;
    margin-right: 8px;
    flex-shrink: 0;

    &.model-gallery__current-img {
      border: 1px solid $green;
    }
  }
}

.model-gallery__button {
  width: 50px;
  height: 50px;
  box-shadow: none;
  border: none;
  outline: none;
  background: rgba(189, 189, 189, 0.5);
  border-radius: 50%;
  margin: 0 3px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background: rgba(130, 130, 130, 0.5);
    outline: none;
    box-shadow: none;
  }

  &::after {
    position: relative;
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    left: 50%;
    border-left: 2px solid $button-color;
    border-bottom: 2px solid $button-color;
  }

  &--back {
    left: 15px;

    &::after {
      transform: translateX(-50%) rotate(45deg);
    }
  }

  &--forward {
    right: 15px;

    &::after {
      transform: translateX(-50%) rotate(225deg);
    }
  }
}

@media (min-width: $ipad-width) {
  .model-gallery__image-wrapper {
    width: 264px;
    height: 264px;
  }

  .model-gallery__preview {
    width: 264px;

    img {
      width: 80px;
      height: 80px;
    }
  }

  .model-gallery__carousel {
    width: 384px;
    align-items: center;
  }
}

@media (min-width: $desktop-width) {
  .model-gallery {
    // margin-right: 64px;
    width: 493px;
    flex-grow: 1;
  }

  .model-gallery__image-wrapper {
    margin-left: auto;
    margin-right: 146px;
  }

  .model-gallery__carousel {
    width: 404px;
    margin-left: auto;
    margin-right: 75px;
    margin-top: 12px;
  }

  .model-gallery__button {
    margin: 0 10px;
  }

  .model-gallery__preview {
    img {
      margin-right: 12px;
    }
  }
}
