.usage {
  margin-bottom: 40px;
}

.usage ul {
  padding-left: 0;
  list-style-type: "-";
  list-style-position: inside;
}

.usage h3 {
  margin-top: 14px;
  margin-bottom: 0;
  text-indent: 15px;
  font-size: 12px;
  line-height: 15px;
  font-weight: inherit;
}


@media (min-width: $ipad-width) {
  p.usage__working-stages {
    margin-top: 17px;
    margin-bottom: 14px;
  }

  .usage h3 {
    margin-top: 17px;
    font-size: 14px;
    line-height: 17px;
  }
}

@media (min-width: $desktop-width) {
  .usage__wrapper {
    width: $desktop-width;
    margin: 0 auto;
    padding: 0 23px;
  }
}
