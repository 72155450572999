.models {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

@media (min-width: $ipad-width) {
  .models {
    margin-bottom: 23px;
  }
}

@media (min-width: $desktop-width) {
  .models {
    margin-bottom: 35px;
  }
}
