.document-link {
  margin-bottom: 10px;
  min-height: 32px;
  max-width: 430px;
  // margin-top: 10px;
  display: block;
  border-radius: 30px;
  padding-left: 72px;
  background-repeat: no-repeat;
  background-position: 24px center, center;
  text-decoration: none;
  color: inherit;

  &--pdf {
    background-image: url("../img/pdf-file_icon.svg"), linear-gradient(to right, $green 0, $green 72px, transparent 72px, transparent 100%);
  }

  &--settings {
    background-image: url("../img/ini-file_icon.svg"), linear-gradient(to right, $green 0, $green 72px, transparent 72px, transparent 100%);
  }

  &:hover,
  &:focus {
    background-color: $green;

    p {
      border-color: $green;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.document-link p {
  margin: 0 auto;
  text-indent: 0;
  text-align: left;
  border: 1px solid #000000;
  border-left: none;
  padding: 0 6px;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  color: #000000;
}


@media (min-width: $ipad-width) {
  .document-link {
    margin-bottom: 10px;
    // width: 647px;
    max-width: none;
    min-height: auto;
    font-size: 18px;
    line-height: 22px;
    align-self: flex-start;
    min-width: 647px;
  }

  .document-link p {
    padding-top: 3px;
    padding-bottom: 6px;
    padding-left: 10px;
  }
}

@media (min-width: $desktop-width) {
  .document-link {
    &--pdf {
      letter-spacing: 0.6;
    }
  }
}
