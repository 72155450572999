.captcha {
  width: auto;
  margin: 0 15px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  border: none;
  padding-top: 0;
}

.captcha__buttons-wrapper {
  width: 24px;
  margin-left: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.captcha__link {
  display: block;
  width: 24px;
  height: 24px;
}

.captcha__image {
  display: block;
  width: 160px;
  height: auto;
}

.captcha_play_image,
.captcha_loading_image,
.captcha_refresh_image {
  display: block;
  width: 24px;
  height: 24px;
}

.captcha label {
  display: none;
}

.captcha__code-input {
  height: 40px;
  width: 100%;
  padding: 5px;
  box-shadow: none;
  border: 1px solid #333333;
  margin-top: 20px;

  &::placeholder {
    font-size: 14px;
    line-height: 17px;
    color: #bdbdbd;
    opacity: 1;
  }
}

.captcha__audio-controls {
  width: 24px;
}

@media (min-width: $ipad-width) {
  .captcha {
    width: auto;
    justify-content: flex-start;
  }

  .captcha__buttons-wrapper {
    margin-left: 20px;
  }

  .captcha__image {
    width: auto;
    height: auto;
  }
}

@media (min-width: 1150px) {
  .captcha {
    width: 614px;
    padding-left: 0;
    padding-right: 0;
  }
}
