.header-contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 12px;
  border-left: 1px solid $green;
}

.header-contacts__link {
  display: block;
  text-decoration: none;
  color: inherit;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  &:hover {
    color: $text-green;
  }

  &--phone {
    padding-bottom: 11px;
  }

  &--email {
    padding-bottom: 4px;
  }
}

@media (min-width: $ipad-width) {
  .header-contacts__link {
    font-size: 17px;
    line-height: 21px;

    &--phone {
      padding-bottom: 6px;
    }

    &--email {
      padding-bottom: 0;
    }
  }
}

@media (min-width: 1150px) {
  .header-contacts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 6px;
    padding-top: 15px;
  }

  .header-contacts__link {
    color: $text-green;
    font-size: 17px;
    line-height: 21px;

    &--email {
      display: none;
    }
  }
}
