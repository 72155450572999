.equipment ul {
  margin-top: 0;
  list-style-position: inside;
  list-style-type: "— ";
  padding-left: 0;
  text-indent: 15px;
}

@media (min-width: $ipad-width) {
  .equipment ul {
    max-width: 560px;
  }
}
